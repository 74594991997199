import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: InputComponent
    }
  ],
  host: {
    '(click)': 'onClick()'
  }
})
export class InputComponent implements OnInit, ControlValueAccessor {
  static refCount = 0;

  inputId = `InputComponent-${InputComponent.refCount++}`;
  @Input() label: string;
  @Input() isFocused: boolean = false;
  @Input() errors: string[];

  @ViewChild('inp') inputElem: ElementRef;

  // Use this property when using type=select
  @Input() options: {[name: string]: string};
  
  /* Pass along this properties to the native input */
  @Input() type: string = "text";
  
  /* ********************************************** */


  value: string = '';
  optionsOpened = false;
  
  onChange: (val: string) => { };
  onTouched: () => { };
  isDisabled: boolean;
  
  constructor(private alertController: AlertController) { 
    
  }

  ngOnInit() {
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
    this.onTouched();
  }

  onInput(value: string) {
    this.value = value;
    this.onChange(value);
  }

  handleSelection = (value) => {
    this.writeValue(value);
    this.onChange(value);
  }

  onClick() {
    if (this.type !== 'select') {
      this.inputElem.nativeElement.focus();
    } else if (!this.optionsOpened) {
      this.optionsOpened = true;
      this.alertController.create()
        .then(alert => {
          alert.header = this.label;

          alert.inputs = Object.keys(this.options)
            .sort((a, b) => this.options[a].localeCompare(this.options[b]))
            .map(key => ({
              type: 'radio',
              name: 'radio',
              value: key,
              label: this.options[key],
              handler: () => { 
                this.handleSelection(key); 
                alert.dismiss(); 
              }
            }));
          
          return alert.present();
        }).finally(() => {
          this.optionsOpened = false;
        });
    }
  }

  writeValue(value: string | null): void {
    if (this.type == 'select') {
      this.value = this.options[value];
    } else {
      this.value = value == null ? '' : value;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
