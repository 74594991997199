import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { IDevice } from 'src/app/services/device-manager.service';

@Component({
  selector: 'app-attach-popup',
  templateUrl: './attach-popup.component.html',
  styleUrls: ['./attach-popup.component.scss'],
})
export class AttachPopupComponent implements OnInit {

  @Input() devices: IDevice[] = [];

  constructor(
    private popoverCtrl: PopoverController,
  ) { }

  ngOnInit() {}

  async dismiss(device?: IDevice) {
    await this.popoverCtrl.dismiss(device);
  }
}
