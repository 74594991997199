import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';


@NgModule({
  declarations: [
    InputComponent,
  ],
  imports: [
    AngularCommonModule,
  ],
  exports: [
    InputComponent,
  ]
})
export class AppCommonModule { 
}
